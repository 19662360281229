import { Button, Col, DatePicker, Flex, Input, Modal, notification, Row, Select } from 'antd';
import React, { forwardRef, memo, useImperativeHandle, useState, useMemo, useEffect } from 'react';
import style from './suaYeuCau.module.less';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { sumBy, uniqueId } from 'lodash';
import { formatNumber } from '../../../../../utils/common';
import { THICC_Master } from '../../ThemMoiNhapKho/BlockYeuCau/master';
import { isValidForm } from '../../../../../utils/validate';

const MAX_ROLL_OPTION = 100;

const initialState = {
    workOrderDate: '',
    rollId: '',
    no: 1,
    sizeThickness: '',
    sizeWidth: '',
    type: '',
    nameCustomer: 'KH',
    positionRoll1: 'H',
    positionRoll2: 'K',
    md: 'D',
    sizeWidthReal: '',
    weight: '',
    numberSplit: 1,
    sizeWidthVia: '',
    totalVia: '',
    ratioTrash: '',

    thicknessMin: '',
    thicknessProduct: '',
    sizeWidthTape: '',
    typeProduct: '',
    stripNumber: '',
    totalWidth: '',
    weightCal: '',
    note: '',
};

const ThemYeuCauModal = forwardRef(({ addRow, getAllTapeInRoll, getRollInfoById }, ref) => {
    const rollMaster = useSelector(state => state.app.listRollManufacture);
    const steelTypeMaster = useSelector(state => state.app.steelTypeMaster);
    const standardMaster = useSelector(state => state.app.listMasterStandard);

    const [visible, setVisible] = useState(false);
    const [state, setState] = useState(initialState);

    const {
        workOrderDate,
        rollId,
        sizeThickness,
        sizeWidth,
        sizeWidthReal,
        weight,
        sizeWidthVia,
        totalVia,
        ratioTrash,
        mass,

        id_xe,
        thicknessMin,
        thicknessProduct,
        sizeWidthTape,
        typeProduct,
        stripNumber,
        note,
    } = state;

    //prettier-ignore
    const onChangeInput = (field, keyboardType = 'none') => event => {
        let val = ['string', 'number'].includes(typeof event) ? event : event.target.value;
        if (keyboardType == 'numeric') {
            val = val.replace(/[^0-9.]/g, '');
        }
        setState(prev => ({...prev, [field]: val}));
    };

    const [filterThickness, set_filterThickness] = useState('');
    const [filterWidth, set_filterWidth] = useState('');
    const [importDate, set_importDate] = useState('');
    const [filterType, set_filterType] = useState('');
    const filterTypeLabel = steelTypeMaster?.find(item => item.value == filterType)?.label || '';
    const rollOption = useMemo(() => {
        return (
            rollMaster?.filter(
                item =>
                    (!importDate || item.importDate == importDate) &&
                    (!filterThickness || item.sizeDepth.toString().includes(filterThickness)) &&
                    (!filterWidth || item.sizeWidth.toString().includes(filterWidth)) &&
                    (!filterTypeLabel || item.steelType == filterTypeLabel),
            ) || []
        );
    }, [rollMaster, importDate, filterWidth, filterThickness, filterTypeLabel]);

    //chọn cuộn sẽ tự động fill thuộc tính của cuộn
    const onSelectRoll = rollId => {
        onChangeInput('rollId')(rollId);
        const rollYeucau = getRollInfoById(rollId);
        const roll = rollOption.find(item => item.id == rollId);

        onChangeInput('sizeThickness')(roll?.sizeDepth || '');
        onChangeInput('sizeWidth')(roll?.sizeWidth || '');
        onChangeInput('type')(roll?.steelType || '');
        onChangeInput('sizeWidthReal')(rollYeucau?.sizeWidthReal || roll?.sizeWidth || '');
        onChangeInput('weight')(rollYeucau?.weight || roll?.netWeight || '');

        if (!!rollYeucau?.workOrderDate) {
            onChangeInput('workOrderDate')(rollYeucau.workOrderDate);
        }
    };

    useEffect(() => {
        //khi chỉnh sửa filter mà cuộn đang được chọn không còn trong list filter mới => clear thông tin cuộn
        // if (rollId != '' && rollOption.filter(item => item.nsmvCoilId == rollId) == 0) {
        //     onChangeInput('rollId')('');
        //     onChangeInput('sizeWidthReal')('');
        //     onChangeInput('weight')('');
        // }
    }, [rollId, rollOption]);

    /**
     *  danh sách các xẻ của cuộn mới (không bao gồm xẻ đang edit này)
     *  trả về rỗng nếu như cuộn này chưa có trong yêu cầu
     */
    const lsTape = getAllTapeInRoll(rollId).concat({ sizeWidthTape, stripNumber });

    const calc_via_width = useMemo(() => {
        var value = Number(sizeWidth) - sumBy(lsTape, i => Number(i.sizeWidthTape) * Number(i.stripNumber));
        return formatNumber(value);
    }, [sizeWidth, lsTape]);
    useEffect(() => {
        onChangeInput('sizeWidthVia')(calc_via_width);
    }, [calc_via_width]);

    const calc_via_weight = useMemo(() => {
        var value = (Number(weight) * 1 * (Number(calc_via_width) - Number(sizeWidth) + Number(sizeWidthReal))) / Number(sizeWidthReal);
        return formatNumber(value);
    }, [calc_via_width, sizeWidth, sizeWidthReal, weight]);
    const calc_via_total = calc_via_weight;

    useEffect(() => {
        onChangeInput('mass')(calc_via_weight);
        onChangeInput('totalVia')(calc_via_weight);
    }, [calc_via_weight]);

    const calc_via_rate = useMemo(() => {
        var val = (calc_via_total / (Number(weight) * 1)) * 100;
        return formatNumber(val);
    }, [calc_via_total, weight]);
    useEffect(() => {
        onChangeInput('ratioTrash')(Number(calc_via_rate));
    }, [calc_via_rate]);

    const submitAddNew = () => {
        var weightCal = (Number(weight) * Number(sizeWidthTape) * Number(stripNumber)) / Number(sizeWidthReal);
        var totalWidth = Number(sizeWidthTape) * Number(stripNumber);

        var newId_xe = uniqueId();
        var newId = `${workOrderDate}-${rollId}-${newId_xe}`;
        const newState = {
            ...state,
            weightCal: formatNumber(weightCal),
            totalWidth: formatNumber(totalWidth),
            // id: newId,
            id_xe: newId_xe,
            nsmvCoilId: rollOption.find(i => i.id == rollId)?.nsmvCoilId || '',
        };
        console.log('Stateing', newState);

        if (!isValidForm(newState, 'note')) {
            notification.error({ message: 'Không được để trống trường bắt buộc' });
        } else if (ratioTrash < 0) {
            notification.error({ message: 'Vượt quá khối lượng tôn cuộn' });
        } else {
            addRow(newState);
            closeEdit();
        }
    };

    const closeEdit = () => {
        setVisible(false);
        setState(initialState);

        set_filterThickness('');
        set_filterWidth('');
        set_filterType('');
        set_importDate('');
    };

    useImperativeHandle(ref, () => ({
        open() {
            setVisible(true);
        },
    }));

    const onChangeThickProduct = value => {
        var day_min = THICC_Master.find(item => item.value == value)?.min || '';
        onChangeInput('thicknessProduct')(value);
        onChangeInput('thicknessMin')(day_min);
        var listBody = standardMaster?.find(i => i.value == typeProduct)?.lstBody || [];
        if (listBody.length > 0) {
            var do_rong_xe = listBody.find(i => i.sizeThickness.toString() == day_min)?.specificSize || '';
            onChangeInput('sizeWidthTape')(do_rong_xe);
        }
    };
    const onChangeThickMin = ({ target }) => {
        var num = target.value.replace(/[^0-9.]/g, '');
        onChangeInput('thicknessMin')(num);
        if (!!num) {
            var listBody = standardMaster?.find(i => i.value == typeProduct)?.lstBody || [];
            if (listBody.length > 0) {
                var do_rong_xe = listBody.find(i => i.sizeThickness.toString() == num)?.specificSize || '';
                onChangeInput('sizeWidthTape')(do_rong_xe);
            }
        }
    };
    const onChangeTypeProduct = value => {
        var listBody = standardMaster?.find(i => i.value == value)?.lstBody || [];
        var do_rong_xe = '';
        if (listBody.length > 0) {
            do_rong_xe = listBody.find(i => i.sizeThickness.toString() == thicknessMin)?.specificSize || '';
        }
        onChangeInput('sizeWidthTape')(do_rong_xe);
        onChangeInput('typeProduct')(value);
    };

    return (
        <Modal title="Thêm quy cách" closeIcon={false} open={visible} onCancel={closeEdit} className={style['modal-container']} width={'82vw'} footer={[]}>
            <div className={style['main']}>
                <h4 style={{ color: '#2792ce', marginTop: '14px' }}>Bộ lọc tìm kiếm </h4>
                <Row
                    gutter={[10, 20]}
                    align={'middle'}
                    style={{ marginTop: '5px', marginBottom: '30px', border: '2px solid #2792ce', padding: '10px 0', borderRadius: '4px', backgroundColor: '#f2f2f2' }}
                >
                    <Col span={2} className={style['filter-title']}>
                        Số cuộn
                        <span style={{ color: 'red' }}>*</span>
                    </Col>
                    <Col span={4}>
                        <Select
                            className={style['filter-select']}
                            onChange={onSelectRoll}
                            value={rollId}
                            showSearch
                            optionFilterProp="label"
                            dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
                            options={rollOption}
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            listHeight={160}
                        />
                    </Col>
                    <Col span={2} className={style['filter-title']}>
                        Ngày nhập cuộn
                    </Col>
                    <Col span={4}>
                        <DatePicker
                            format={'DD/MM/YYYY'}
                            className={style['filter-input']}
                            placeholder="Chọn ngày"
                            value={!!importDate ? dayjs(importDate, 'DD/MM/YYYY') : undefined}
                            onChange={(date, dateStr) => set_importDate(dateStr)}
                        />
                    </Col>
                    <Col span={2} className={style['filter-title']}>
                        {`Dày x rộng(mm)`}
                    </Col>
                    <Col span={4}>
                        <Flex align="center" style={{ width: '90%' }}>
                            <Input className={style['filter-input']} value={filterThickness} onChange={({ target }) => set_filterThickness(target.value.replace(/[^0-9.]/g, ''))} />
                            <div style={{ marginLeft: '10px', marginRight: '10px' }}>×</div>
                            <Input className={style['filter-input']} value={filterWidth} onChange={({ target }) => set_filterWidth(target.value.replace(/[^0-9.]/g, ''))} />
                        </Flex>
                    </Col>
                    <Col span={2} className={style['filter-title']}>
                        Loại thép
                    </Col>
                    <Col span={4}>
                        <Select
                            className={style['filter-select']}
                            value={filterType}
                            onChange={value => set_filterType(value)}
                            optionFilterProp="label"
                            dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
                            options={steelTypeMaster}
                            placeholder="Chọn loại thép"
                            allowClear
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            listHeight={160}
                            showSearch
                        />
                    </Col>
                </Row>
                <Row gutter={[10, 20]} align={'middle'} style={{ marginTop: '20px' }}>
                    <Col span={2} className={style['filter-title']}>
                        Ngày lệnh
                    </Col>
                    <Col span={4}>
                        <DatePicker
                            format={'DD/MM/YYYY'}
                            className={style['filter-input']}
                            value={!!workOrderDate ? dayjs(workOrderDate, 'DD/MM/YYYY') : undefined}
                            onChange={(date, dateString) => {
                                onChangeInput('workOrderDate')(dateString);
                            }}
                            placeholder="Chọn ngày"
                        />
                    </Col>
                    <Col span={2} className={style['filter-title']}>
                        {`Rộng thực tế(mm)`}
                        <span style={{ color: 'red' }}>*</span>
                    </Col>
                    <Col span={4}>
                        <Input className={style['filter-input']} value={sizeWidthReal} onChange={onChangeInput('sizeWidthReal', 'numeric')} />
                    </Col>
                    <Col span={2} className={style['filter-title']}>
                        {`Trọng lượng(kg)`}
                        <span style={{ color: 'red' }}>*</span>
                    </Col>
                    <Col span={4}>
                        <Input className={style['filter-input']} value={weight} onChange={onChangeInput('weight', 'numeric')} />
                    </Col>

                    <Col span={6} />

                    <Col span={2} className={style['filter-title']}>
                        {`Dày sản phẩm`}
                        <span style={{ color: 'red' }}>*</span>
                    </Col>
                    <Col span={4}>
                        <Select
                            className={style['filter-select']}
                            onChange={onChangeThickProduct}
                            value={thicknessProduct}
                            optionFilterProp="label"
                            dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
                            options={THICC_Master}
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            listHeight={160}
                            showSearch
                        />
                    </Col>
                    <Col span={2} className={style['filter-title']}>
                        {`Dày MIN(mm)`}
                        <span style={{ color: 'red' }}>*</span>
                    </Col>
                    <Col span={4}>
                        <Input className={style['filter-input']} value={thicknessMin} onChange={onChangeThickMin} />
                    </Col>
                    <Col span={2} className={style['filter-title']}>
                        Loại sản phẩm
                        <span style={{ color: 'red' }}>*</span>
                    </Col>
                    <Col span={4}>
                        <Select
                            className={style['filter-select']}
                            onChange={onChangeTypeProduct}
                            value={typeProduct}
                            optionFilterProp="label"
                            dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
                            options={standardMaster}
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            listHeight={160}
                            showSearch
                        />
                    </Col>
                    <Col span={2} className={style['filter-title']}>
                        {`Độ rộng xẻ(mm)`}
                        <span style={{ color: 'red' }}>*</span>
                    </Col>
                    <Col span={4}>
                        <Input className={style['filter-input']} value={sizeWidthTape} onChange={onChangeInput('sizeWidthTape', 'numeric')} />
                    </Col>
                    <Col span={2} className={style['filter-title']}>
                        Số dải
                        <span style={{ color: 'red' }}>*</span>
                    </Col>
                    <Col span={4}>
                        <Input className={style['filter-input']} value={stripNumber} onChange={onChangeInput('stripNumber', 'numeric')} />
                    </Col>
                    <Col span={2}>Ghi chú</Col>
                    <Col span={10}>
                        <Input.TextArea className={style['filter-input']} value={note} onChange={onChangeInput('note')} />
                    </Col>
                    <Col span={6}></Col>

                    <Col span={2} className={style['filter-title']}>
                        {'Rộng via(mm)'}
                    </Col>
                    <Col span={4}>
                        <Input className={style['filter-input']} value={sizeWidthVia} disabled />
                    </Col>
                    <Col span={2} className={style['filter-title']}>
                        {'Khối lượng via(kg)'}
                    </Col>
                    <Col span={4}>
                        <Input className={style['filter-input']} value={mass} disabled />
                    </Col>
                    <Col span={2} className={style['filter-title']}>
                        {'Tổng via(kg)'}
                    </Col>
                    <Col span={4}>
                        <Input className={style['filter-input']} value={totalVia} disabled />
                    </Col>
                    <Col span={2} className={style['filter-title']}>
                        {'Tỉ lệ phế(%)'}
                    </Col>
                    <Col span={4}>
                        <Input className={style['filter-input']} value={ratioTrash} disabled />
                    </Col>
                </Row>

                <Row gutter={[20]} align={'middle'} style={{ justifyContent: 'flex-end', marginTop: '20' }}>
                    <Col>
                        <Button onClick={closeEdit}>Đóng</Button>
                    </Col>
                    <Col>
                        <Button type="primary" onClick={submitAddNew}>
                            Lưu
                        </Button>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
});

export default memo(ThemYeuCauModal);
