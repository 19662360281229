import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import style from './BlockYeuCau.module.less';
import { Breadcrumb, Button, Col, DatePicker, Flex, Input, Row, Select, Space, Table, notification } from 'antd';
import Icon from '@ant-design/icons/lib/components/AntdIcon';
import { CopyOutlined, DeleteOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { current } from '@reduxjs/toolkit';
import cn from 'classnames';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { produce } from 'immer';
import { isEqual, sumBy, uniqueId } from 'lodash';
import TextArea from 'antd/es/input/TextArea';
import { THICC_Master } from './master';

const TEST_DATA = [
    {
        value: '1',
        label: 'Item A',
    },
    {
        value: '2',
        label: 'Item B',
    },
    {
        value: '3',
        label: 'Item C',
    },
];

const MAX_ROLL_OPTION = 100;

const BlockYeuCau = forwardRef(({ idBlock, item, onChangeCuon, onChangeXe, addCuon, addBangXe, deleteCuon, deleteXe, errors, lsNgayLenh, lsRollId }, ref) => {
    const { id, rollId, no, sizeThickness, sizeWidth, type, nameCustomer, positionRoll1, positionRoll2, md, sizeWidthReal, weight, lstStandard } = item;
    //danh sách roll id khác trong yêu cầu
    const lsOtherRollID = lsRollId.filter(i => i != rollId);

    const idCuon = `${idBlock}-${id}`;
    const errorCuon = errors.includes(idCuon);
    const _ref = useRef(null);

    const [importDate, set_importDate] = useState('');
    const rollMaster = useSelector(state => state.app.listRollManufacture);
    const steelTypeMaster = useSelector(state => state.app.steelTypeMaster);

    // filter input để tìm cuộn dễ hơn
    const [filterThickness, set_filterThickness] = useState('');
    const [filterWidth, set_filterWidth] = useState('');
    const [filterType, set_filterType] = useState('');
    const filterTypeLabel = steelTypeMaster?.find(item => item.value == filterType)?.label || '';

    // list option cuộn trong thanh dropdown (Max default 100)
    const rollOption = useMemo(() => {
        return (
            rollMaster?.filter(
                item =>
                    (!importDate || item.importDate == importDate) &&
                    (!filterThickness || item.sizeDepth.toString().includes(filterThickness)) &&
                    (!filterWidth || item.sizeWidth.toString().includes(filterWidth)) &&
                    (!filterTypeLabel || item.steelType == filterTypeLabel),
            ) || []
        );
    }, [rollMaster, importDate, filterWidth, filterThickness, filterTypeLabel]);

    //chọn cuộn sẽ tự động fill thuộc tính của cuộn
    const onSelectRoll = rollId => {
        onChangeCuon(idCuon, 'rollId')(rollId);
        const roll = rollOption.find(item => item.id == rollId);
        onChangeCuon(idCuon, 'sizeThickness')(roll?.sizeDepth || '');
        onChangeCuon(idCuon, 'sizeWidth')(roll?.sizeWidth || '');
        onChangeCuon(idCuon, 'type')(roll?.steelType || '');
        onChangeCuon(idCuon, 'sizeWidthReal')(roll?.sizeWidth || '');
        onChangeCuon(idCuon, 'weight')(roll?.netWeight || '');
    };
    useEffect(() => {
        //khi chỉnh sửa filter mà cuộn đang được chọn không còn trong list filter mới => clear thông tin cuộn
        if (!!rollId && !rollOption.find(item => item.id == rollId)) {
            onChangeCuon(idCuon, 'rollId')('');
            onChangeCuon(idCuon, 'sizeWidthReal')('');
            onChangeCuon(idCuon, 'weight')('');
            onChangeCuon(idCuon, 'sizeThickness')('');
            onChangeCuon(idCuon, 'sizeWidth')('');
            onChangeCuon(idCuon, 'type')('');
        }
    }, [rollId, rollOption]);

    //list thành phẩm (Lấy master từ server)
    const standardMaster = useSelector(state => state.app.listMasterStandard);

    //kiểm tra tổng khối lượng tính toán của xẻ có vượt quá khối lượng ban đầu không
    const overWeight = useMemo(() => {
        var total_weight_xe =
            sumBy(lstStandard, item => {
                var numValue = (Number(weight) * Number(item.sizeWidthTape) * Number(item.stripNumber)) / Number(sizeWidthReal);
                return isNaN(numValue) || numValue == Infinity ? 0 : numValue;
            }) || 0;
        if (total_weight_xe > weight) return true;
        return false;
    }, [lstStandard, sizeWidthReal, weight]);

    const calc_via_width = useMemo(() => {
        var value = Number(sizeWidth) - sumBy(lstStandard, i => Number(i.sizeWidthTape) * Number(i.stripNumber));
        return isNaN(value) || value == Infinity ? '0' : value.toFixed(2);
    }, [sizeWidth, lstStandard]);
    useEffect(() => {
        onChangeCuon(idCuon, 'sizeWidthVia')(Number(calc_via_width));
    }, [calc_via_width]);

    const calc_via_weight = useMemo(() => {
        var value = (Number(weight) * 1 * (Number(calc_via_width) - Number(sizeWidth) + Number(sizeWidthReal))) / Number(sizeWidthReal);
        return isNaN(value) || value == Infinity ? '0' : value.toFixed(2);
    }, [calc_via_width, sizeWidth, sizeWidthReal, weight]);
    const calc_via_total = calc_via_weight;

    useEffect(() => {
        onChangeCuon(idCuon, 'mass')(Number(calc_via_weight));
        onChangeCuon(idCuon, 'totalVia')(Number(calc_via_weight));
    }, [calc_via_weight]);

    const calc_via_rate = useMemo(() => {
        var val = (calc_via_total / (Number(weight) * 1)) * 100;
        return isNaN(val) || val == Infinity ? '0' : val.toFixed(2);
    }, [calc_via_total, weight]);
    useEffect(() => {
        onChangeCuon(idCuon, 'ratioTrash')(Number(calc_via_rate));
    }, [calc_via_rate]);

    const columns = [
        {
            title: 'Dày sp(mm)*',
            dataIndex: 'thicknessProduct',
            key: 'thicknessProduct',
            width: '90px',
            align: 'center',
            render: (text, item, index) => {
                var err_xe = errors.includes(`${idCuon}-${item.id_xe}`);
                var onSelect = value => {
                    var day_min = THICC_Master.find(item => item.value == value)?.min || '';
                    onChangeXe(`${idCuon}-${item.id_xe}`, 'thicknessProduct')(value);
                    onChangeXe(`${idCuon}-${item.id_xe}`, 'thicknessMin')(day_min);
                    var listBody = standardMaster?.find(i => i.value == item.typeProduct)?.lstBody || [];
                    if (listBody.length > 0) {
                        var do_rong_xe = listBody.find(i => i.sizeThickness.toString() == day_min)?.specificSize || '';
                        onChangeXe(`${idCuon}-${item.id_xe}`, 'sizeWidthTape')(do_rong_xe);
                    }
                };
                return (
                    <Select
                        className={cn(style['table-select'], err_xe && item.thicknessProduct == '' && style['warning-input'])}
                        options={THICC_Master}
                        onChange={onSelect}
                        value={item.thicknessProduct}
                        optionFilterProp="label"
                        dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        showSearch
                        listHeight={160}
                    />
                );
            },
        },
        {
            title: 'Dày MIN(mm)',
            dataIndex: 'thicknessMin',
            key: 'thicknessMin',
            width: '90px',
            align: 'center',
            render: (text, item, index) => {
                var err_xe = errors.includes(`${idCuon}-${item.id_xe}`);
                var onChangeText = ({ target }) => {
                    var num = target.value.replace(/[^0-9.]/g, '');
                    onChangeXe(`${idCuon}-${item.id_xe}`, 'thicknessMin')(num);
                    if (!!num) {
                        var listBody = standardMaster?.find(i => i.value == item.typeProduct)?.lstBody || [];
                        if (listBody.length > 0) {
                            var do_rong_xe = listBody.find(i => i.sizeThickness.toString() == num)?.specificSize || '';
                            onChangeXe(`${idCuon}-${item.id_xe}`, 'sizeWidthTape')(do_rong_xe);
                        }
                    }
                };
                return (
                    <Input
                        className={cn(style['table-input'], err_xe && item.thicknessMin == '' && style['warning-input'])}
                        value={!!item.thicknessMin ? item.thicknessMin : ''}
                        //disabled
                        onChange={onChangeText}
                    />
                );
            },
        },
        {
            title: 'Loại sản phẩm(mm)*',
            dataIndex: 'typeProduct',
            key: 'typeProduct',
            width: '100px',
            align: 'center',
            render: (text, item, index) => {
                var err_xe = errors.includes(`${idCuon}-${item.id_xe}`);
                var onSelect = val => {
                    //var value = typeof val == 'string' ? val.replace(/[^0-9.]/g, '') : val;
                    var value = val;
                    if (!!value) {
                        var listBody = standardMaster?.find(i => i.value == value)?.lstBody || [];
                        var do_rong_xe = '';
                        if (listBody.length > 0) {
                            do_rong_xe = listBody.find(i => i.sizeThickness.toString() == item.thicknessMin)?.specificSize || '';
                        }
                        onChangeXe(`${idCuon}-${item.id_xe}`, 'sizeWidthTape')(do_rong_xe);
                        onChangeXe(`${idCuon}-${item.id_xe}`, 'typeProduct')(value);
                    }
                };
                return (
                    <Select
                        className={cn(style['table-select'], err_xe && item.typeProduct == '' && style['warning-input'])}
                        options={standardMaster || []}
                        onChange={onSelect}
                        value={item.typeProduct}
                        optionFilterProp="label"
                        dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        showSearch
                        listHeight={160}
                        onSearch={onSelect}
                    />
                );
            },
        },
        {
            title: 'Rộng(mm)',
            dataIndex: 'sizeWidthTape',
            key: 'sizeWidthTape',
            width: '80px',
            align: 'center',
            render: (text, item, index) => {
                var err_xe = errors.includes(`${idCuon}-${item.id_xe}`);
                return (
                    <Input
                        className={cn(style['table-input'], err_xe && item.sizeWidthTape == '' && style['warning-input'])}
                        value={item.sizeWidthTape}
                        //disabled
                        onChange={onChangeXe(`${idCuon}-${item.id_xe}`, 'sizeWidthTape', 'numeric')}
                    />
                );
            },
        },
        {
            title: 'Số dải*',
            dataIndex: 'stripNumber',
            align: 'center',
            key: 'stripNumber',
            width: '70px',
            render: (text, item, index) => {
                var err_xe = errors.includes(`${idCuon}-${item.id_xe}`);
                return (
                    <Input
                        className={cn(style['table-input'], err_xe && item.stripNumber == '' && style['warning-input'])}
                        value={item.stripNumber}
                        onChange={onChangeXe(`${idCuon}-${item.id_xe}`, 'stripNumber', 'numeric')}
                    />
                );
            },
        },
        {
            title: 'Tổng độ rộng(mm)',
            // dataIndex: 'sizeWidthTape',
            align: 'center',
            key: 'totalWidth',
            width: '90px',
            render: (text, item, index) => <Input disabled className={style['table-input']} value={Number(item.sizeWidthTape) * Number(item.stripNumber)} />,
        },
        {
            title: 'Khối lượng tính',
            // dataIndex: 'weightCal',
            align: 'center',
            key: 'weightCal',
            width: '90px',
            render: (text, item, index) => {
                var numValue = (Number(weight) * Number(item.sizeWidthTape) * Number(item.stripNumber)) / Number(sizeWidthReal);
                return (
                    <Input
                        disabled
                        className={cn(style['table-input'], index == lstStandard.length - 1 && overWeight && style['warning-input'])}
                        value={isNaN(numValue) || numValue == Infinity ? 0 : numValue.toFixed(2)}
                    />
                );
            },
        },
        {
            title: 'Ghi chú',
            dataIndex: 'note',
            key: 'note',
            width: '220px',
            align: 'center',
            render: (text, item, index) => (
                <TextArea
                    style={{ padding: '3px' }}
                    autoSize={{ minRows: 3, maxRows: 6 }}
                    className={style['table-input']}
                    value={item.note}
                    onChange={onChangeXe(`${idCuon}-${item.id_xe}`, 'note')}
                />
            ),
        },
        {
            title: '',
            key: 'option',
            width: '0px',
            align: 'center',
            render: (value, item, index) => (
                <Space>
                    <div className={style['table-button']} onClick={() => addBangXe(idCuon, item)}>
                        <PlusOutlined style={{ color: '#2c3782', fontSize: '12px' }} />
                    </div>
                    <div className={style['table-button']} onClick={() => deleteXe(`${idCuon}-${item.id_xe}`)}>
                        <MinusOutlined style={{ color: '#2c3782', fontSize: '12px' }} />
                    </div>
                </Space>
            ),
        },
    ];

    useImperativeHandle(ref, () => ({
        scrollTo() {
            //console.log('Scroll into this view!');
            _ref.current?.scrollIntoView({ behavior: 'smooth' });
        },
    }));

    //khi thêm mới cuộn tự scroll xuống
    // useEffect(() => {
    //     _ref.current?.scrollIntoView({ behavior: 'smooth' });
    // }, []);
    return (
        <div className={[style['main']]} ref={_ref}>
            <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <h4 style={{ color: 'red' }}>Tôn cuộn </h4>
                <Row style={{ gap: '20px', paddingRight: '10px' }}>
                    <div style={{ cursor: 'pointer' }} onClick={() => addCuon(idBlock)}>
                        <PlusOutlined style={{ fontSize: '20px' }} />
                    </div>
                    <div style={{ cursor: 'pointer' }} onClick={() => deleteCuon(idCuon)}>
                        <DeleteOutlined style={{ fontSize: '20px' }} />
                    </div>
                </Row>
            </Row>
            <h4 style={{ color: '#2792ce', marginTop: '14px' }}>Bộ lọc tìm kiếm </h4>
            <Row className={cn(style['row-input'], style['filter-block'])} style={{ alignItems: 'center', position: 'relative' }}>
                {/* <div className={style['filter-block-title']} style={{ position: 'absolute', top: -20, left: 10 }}>
                    <h4 style={{ color: '#2792ce' }}>Bộ lọc tìm kiếm </h4>
                </div> */}
                <Col span={2} className={style['filter-title']}>
                    Ngày nhập kho
                </Col>
                <Col span={4}>
                    <DatePicker
                        format={'DD/MM/YYYY'}
                        className={style['filter-input']}
                        placeholder="Chọn ngày"
                        value={!!importDate ? dayjs(importDate, 'DD/MM/YYYY') : undefined}
                        onChange={(date, dateStr) => set_importDate(dateStr)}
                    />
                </Col>
                <Col span={2} className={style['filter-title']}>
                    {'Dày x rộng(mm)'}
                </Col>
                <Col span={4}>
                    <Row style={{ justifyContent: 'space-between', width: '85%', alignItems: 'center' }}>
                        <Input className={cn(style['small-input'])} value={filterThickness} onChange={({ target }) => set_filterThickness(target.value.replace(/[^0-9.]/g, ''))} />
                        <h5>x</h5>
                        <Input className={cn(style['small-input'])} value={filterWidth} onChange={({ target }) => set_filterWidth(target.value.replace(/[^0-9.]/g, ''))} />
                    </Row>
                </Col>
                <Col span={2} className={style['filter-title']}>
                    Loại thép
                </Col>
                <Col span={4}>
                    <Select
                        className={cn(style['filter-select'])}
                        value={filterType}
                        onChange={value => set_filterType(value)}
                        optionFilterProp="label"
                        dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
                        options={steelTypeMaster}
                        placeholder="Chọn loại thép"
                        listHeight={160}
                        //allowClear
                    />
                </Col>
            </Row>

            <Row className={style['row-input']} style={{ rowGap: '30px' }}>
                <Col span={2} className={style['filter-title']}>
                    Mã cuộn<span style={{ color: 'red' }}>*</span>
                </Col>
                <Col span={4}>
                    <Select
                        className={cn(style['filter-select'], errorCuon && rollId == '' && style['warning-input'])}
                        options={rollOption.filter(r => !lsOtherRollID.includes(r.id)).slice(0, MAX_ROLL_OPTION)}
                        onChange={onSelectRoll}
                        value={rollId}
                        optionFilterProp="label"
                        dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        showSearch
                        listHeight={160}
                        //allowClear
                    />
                </Col>
                <Col span={2} className={style['filter-title']}>
                    Loại thép<span style={{ color: 'red' }}>*</span>
                </Col>
                <Col span={4}>
                    <Select
                        className={cn(style['filter-select'], errorCuon && type == '' && style['warning-input'])}
                        value={type}
                        onChange={onChangeCuon(idCuon, 'type')}
                        optionFilterProp="label"
                        dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
                        options={steelTypeMaster}
                        placeholder="Chọn loại thép"
                        listHeight={160}
                        //allowClear
                    />
                </Col>
                <Col span={2} className={style['filter-title']}>
                    {'Dày x rộng(mm)'}
                    <span style={{ color: 'red' }}>*</span>
                </Col>
                <Col span={4}>
                    <Row style={{ justifyContent: 'space-between', width: '85%', alignItems: 'center' }}>
                        <Input
                            className={cn(style['small-input'], errorCuon && sizeThickness == '' && style['warning-input'])}
                            value={sizeThickness}
                            onChange={onChangeCuon(idCuon, 'sizeThickness', 'numeric')}
                        />
                        <h5>x</h5>
                        <Input
                            className={cn(style['small-input'], errorCuon && sizeWidth == '' && style['warning-input'])}
                            value={sizeWidth}
                            onChange={onChangeCuon(idCuon, 'sizeWidth', 'numeric')}
                        />
                    </Row>
                </Col>
                <Col span={2} className={style['filter-title']}>
                    {'Rộng thực tế(mm)'}
                    <span style={{ color: 'red' }}>*</span>
                </Col>
                <Col span={4}>
                    <Input
                        className={cn(style['filter-input'], errorCuon && sizeWidthReal == '' && style['warning-input'])}
                        value={sizeWidthReal}
                        onChange={onChangeCuon(idCuon, 'sizeWidthReal', 'numeric')}
                    />
                </Col>
                <Col span={2} className={style['filter-title']}>
                    {'Trọng lượng(kg)'}
                    <span style={{ color: 'red' }}>*</span>
                </Col>
                <Col span={4}>
                    <Input
                        className={cn(style['filter-input'], errorCuon && weight == '' && style['warning-input'])}
                        value={weight}
                        onChange={onChangeCuon(idCuon, 'weight', 'numeric')}
                    />
                </Col>
                <Col span={2} className={style['filter-title']}>
                    Mác KH
                </Col>
                <Col span={4}>
                    <Input
                        className={cn(style['filter-input'], errorCuon && nameCustomer == '' && style['warning-input'])}
                        value={nameCustomer}
                        onChange={onChangeCuon(idCuon, 'nameCustomer')}
                    />
                </Col>
                <Col span={2} className={style['filter-title']}>
                    Vị trí cuộn
                </Col>
                <Col span={4}>
                    <Row style={{ justifyContent: 'space-between', width: '85%', alignItems: 'center' }}>
                        <Input
                            className={cn(style['small-input'], errorCuon && positionRoll1 == '' && style['warning-input'])}
                            value={positionRoll1}
                            onChange={onChangeCuon(idCuon, 'positionRoll1')}
                        />
                        <h5>x</h5>
                        <Input
                            className={cn(style['small-input'], errorCuon && positionRoll2 == '' && style['warning-input'])}
                            value={positionRoll2}
                            onChange={onChangeCuon(idCuon, 'positionRoll2')}
                        />
                    </Row>
                </Col>
                <Col span={2} className={style['filter-title']}>
                    M/D
                </Col>
                <Col span={4}>
                    <Input className={cn(style['filter-input'], errorCuon && md == '' && style['warning-input'])} value={md} onChange={onChangeCuon(idCuon, 'md')} />
                </Col>
            </Row>
            <h4 style={{ color: 'red', marginTop: '25px' }}>Danh sách xẻ</h4>
            <Table
                columns={columns}
                dataSource={lstStandard}
                className={style['table']}
                rowKey="key"
                // ref={table}
                scroll={{ y: lstStandard.length > 10 ? 580 : null }}
                pagination={false}
                //pagination={{ position: ['none', 'none'], pageSize: 500000 }}
            />
            <h4 style={{ color: 'red', marginTop: '20px' }}>Via</h4>
            <Row className={style['row-input']}>
                <Col span={2} className={style['filter-title']}>
                    {'Rộng thực tế(mm)'}
                </Col>
                <Col span={4}>
                    <Input disabled className={style['filter-input']} value={calc_via_width} />
                </Col>
                <Col span={2} className={style['filter-title']}>
                    {'Khối lượng via(kg)'}
                </Col>
                <Col span={4}>
                    <Input disabled className={style['filter-input']} value={calc_via_weight} />
                </Col>
                <Col span={2} className={style['filter-title']}>
                    {'Tổng via(kg)'}
                </Col>
                <Col span={4}>
                    <Input disabled className={style['filter-input']} value={calc_via_total} />
                </Col>
                <Col span={2} className={style['filter-title']}>
                    Tỷ lệ phế
                </Col>
                <Col span={4}>
                    <Input disabled className={style['filter-input']} value={`${calc_via_rate}%`} />
                </Col>
            </Row>
        </div>
    );
});

export default memo(BlockYeuCau);
