import { takeLatest, call, put } from "redux-saga/effects";
import { common_post, HLog, localRemove, localSet } from "../../helpers";
import { apis, keys } from "../../constants";
import AxiosService from "../../common/AxiosService";
import { doInitMasterData, setAppProperties } from "../slices/appSlice";
import { uniqueRollList } from "../../utils/common";

export function* watchAppSaga() {
  yield takeLatest(doInitMasterData.type, handleLoadSteelTypeMaster);
  yield takeLatest(doInitMasterData.type, handleLoadMasterShift);
  yield takeLatest(doInitMasterData.type, handleLoadMasterTeam);
  yield takeLatest(doInitMasterData.type, handleLoadMasterCustomerType);
  yield takeLatest(doInitMasterData.type, handleLoadListEmailUser);
  yield takeLatest(doInitMasterData.type, handleLoadMasterRollManufacture);
  yield takeLatest(doInitMasterData.type, handleLoadMasterStandard);
}

export function* handleLoadSteelTypeMaster(action) {
  try {
    const response = yield AxiosService.post(apis.Url_Api_Get_MasterData_SteelType, {})

    HLog("handleLoadSteelTypeMaster response", response);
    const { data } = response.data;
    if (!!data && response.data.status === keys.api_request_status_success_code) {
     yield put( setAppProperties({
      steelTypeMaster:   data.map(item  => ({ value: item.typeAcronym , label: item.steelTypeName }))
    }))
    } else {
      yield put( setAppProperties({
        steelTypeMaster: []
      }))
    }
  } catch (error) {
    HLog("handleLoadSteelTypeMaster Error", error);
  }
}

export function* handleLoadMasterShift(action) {
  try {
    const response = yield AxiosService.post(apis.Url_Api_Get_MasterData_Shift, {})

    HLog("handleLoadMasterShift response", response);
    const { data } = response.data;
    if (!!data && response.data.status === keys.api_request_status_success_code) {
     yield put( setAppProperties({
      shiftMaster:  [{ value: '' , label: 'Tất cả' }].concat(data.map(item  => ({ value: item.shiftWorkName , label: item.shiftWorkName })))
    }))
    } else {
      yield put( setAppProperties({
        shiftMaster: []
      }))
    }
  } catch (error) {
    HLog("handleLoadMasterShift Error", error);
  }
}

export function* handleLoadMasterTeam(action) {
  try {
    const response = yield AxiosService.post(apis.Url_Api_Get_MasterData_Team, {})

    HLog("handleLoadMasterTeam response", response);
    const { data } = response.data;
    if (!!data && response.data.status === keys.api_request_status_success_code) {
     yield put( setAppProperties({
      teamMaster: [{ value: '' , label: 'Tất cả' }].concat(data.map(item  => ({ value: item.teamWorkName , label: item.teamWorkName })))
    }))
    } else {
      yield put( setAppProperties({
        teamMaster: []
      }))
    }
  } catch (error) {
    HLog("handleLoadMasterTeam Error", error);
  }
}

export function* handleLoadMasterCustomerType(action) {
  try {
    const response = yield AxiosService.post(apis.Url_Api_Get_MasterData_CustomerType, {})

    HLog("handleLoadMasterCustomerType response", response);
    const { data } = response.data;
    if (!!data && response.data.status === keys.api_request_status_success_code) {
     yield put( setAppProperties({
      customerMaster: [{ value: '' , label: '' }].concat(data.map(item  => ({ value: item.nameAcronym , label: item.name })))
    }))
    } else {
      yield put( setAppProperties({
        customerMaster: []
      }))
    }
  } catch (error) {
    HLog("handleLoadMasterCustomerType Error", error);
  }
}

export function* handleLoadListEmailUser(action) {
  try {
    const response = yield AxiosService.get(apis.Url_Api_Get_ListEmailUser)

    HLog("handLoadListEmailUser response", response);
    const { data } = response.data;
    if (!!data && response.data.status === keys.api_request_status_success_code) {
     yield put( setAppProperties({
      listEmailUser: data
    }))
    } else {
      yield put( setAppProperties({
        listEmailUser: []
      }))
    }
  } catch (error) {
    HLog("handLoadListEmailUser Error", error);
  }
}

export function* handleLoadMasterRollManufacture(action) {
  try {
    const response = yield AxiosService.get(`${apis.Url_Api_Get_MasterTape_RollManufacture}?isForTape=false`)

    HLog("handleLoadMasterRollManufacture response", response);
    const { data } = response.data;
    if (!!data && response.data.status === keys.api_request_status_success_code) {
     yield put( setAppProperties({
      listRollManufacture: uniqueRollList(data).map(item => ({...item, label: item.nsmvCoilId, value: item.id }))
    }))
    } else {
      yield put( setAppProperties({
        listRollManufacture: []
      }))
    }
  } catch (error) {
    HLog("handleLoadMasterRollManufacture Error", error);
  }
}

export function* handleLoadMasterStandard(action) {
  try {
    const response = yield AxiosService.get(apis.Url_Api_Get_MasterTape_Standard)

    HLog("handleLoadMasterStandard response", response);
    const { data } = response.data;
    if (!!data && response.data.status === keys.api_request_status_success_code) {
     yield put( setAppProperties({
      listMasterStandard: data.map(item => {
        var label =  item.sizeWidthTemp1;
        if(!!item.sizeWidthTemp2) label += `x${item.sizeWidthTemp2}`
        if(!!item.sizeWidthTemp3) label += `x${item.sizeWidthTemp3}`
        if(!!item.sizeWidthTemp4) label += `x${item.sizeWidthTemp4}`
        // label += ' mm'
        return {...item, value: label, label}
      })
    }))
    } else {
      yield put( setAppProperties({
        listMasterStandard: []
      }))
    }
  } catch (error) {
    HLog("handleLoadMasterStandard Error", error);
  }
}


