import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Breadcrumb, Button, Col, Input, Space, Row, Table, Select, Pagination } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import AxiosService from '../../../../common/AxiosService';
import { useDispatch } from 'react-redux';
import style from './QuyChuanBangXe.module.less';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import configs from '../../../../constants/configs';

const QuyChuanBangXe = () => {
    const dispatch = useDispatch();
    const standardMaster = useSelector(state => state.app.listMasterStandard);
    const standardTable = useMemo(() => {
        if (!standardMaster) return [];
        return standardMaster.reduce((prev, next) => {
            var lsValue = next.lstBody.map(item => ({ typeProduct: next.label, thicknessProduct: item.specificSize, widthTape: item.sizeThickness })) || [];
            return prev.concat(lsValue);
        }, []);
    }, [standardMaster]);

    const [filterThick, set_filterThick] = useState('');
    const [filterType, set_filterType] = useState('');
    const [filterWidth, set_filterWidth] = useState('');
    const [dataSource, set_dataSource] = useState([]);
    useEffect(() => {
        if (standardTable) set_dataSource(standardTable);
    }, [standardTable]);

    const onFiltterData = () => {
        var newSource = dataSource.filter(
            i => (i.typeProduct == filterType || !filterType) && (i.thicknessProduct == filterThick || !filterThick) && (i.widthTape == filterWidth || !filterWidth),
        );
        set_dataSource(newSource);
    };
    const onClearFilter = () => {
        set_filterThick('');
        set_filterType('');
        set_filterWidth('');
        set_dataSource(standardTable);
    };

    const [currentPage, set_currentPage] = useState(1);
    const [pageSize, set_pageSize] = useState(10);
    const onChangePage = val => {
        set_currentPage(val);
    };
    const onChangePageSize = val => {
        set_pageSize(val);
        set_currentPage(1);
    };

    const columns = [
        {
            title: 'Loại sản phẩm(mm)',
            dataIndex: 'typeProduct',
            key: 'typeProduct',
            // width: '90px',
            align: 'center',
        },
        {
            title: 'Dày sp(mm)',
            dataIndex: 'thicknessProduct',
            key: 'thicknessProduct',
            // width: '90px',
            align: 'center',
        },
        {
            title: 'Rộng băng xẻ(mm)',
            dataIndex: 'widthTape',
            key: 'widthTape',
            // width: '90px',
            align: 'center',
        },
        {
            title: '',
            key: 'option',
            width: '120px',
            align: 'center',
            render: (value, item, index) => (
                <Space>
                    <div className={style['table-button']} onClick={() => {}}>
                        <EditOutlined style={{ color: '#2c3782', fontSize: '15px' }} />
                    </div>
                    <div style={{ width: '10px' }} />
                    <div className={style['table-button']} onClick={() => {}}>
                        <DeleteOutlined style={{ color: '#2c3782', fontSize: '15px' }} />
                    </div>
                </Space>
            ),
        },
    ];

    const [thickProduct, set_thickProduct] = useState('');
    const [typeProduct, set_typeProduct] = useState('');
    const [widthTape, set_widthTape] = useState('');
    const [submited, set_submited] = useState(false);

    const onSumbitNewValue = () => {
        set_submited(true);
        if (!!thickProduct && !!typeProduct && !!widthTape) {
            //call api save new value
        }
    };
    return (
        <div className={style['main-container']}>
            <Breadcrumb
                items={[
                    {
                        title: 'Quản lý dữ liệu',
                    },
                    {
                        title: 'Quy chuẩn khổ băng xẻ',
                    },
                ]}
            />
            <div className={style['main']}>
                <h3 style={{ color: 'black' }}>Thêm giá trị mới</h3>
                <Row className={style['row-input']}>
                    <Col span={2}>{`Loại sp(mm)`}</Col>
                    <Col span={4}>
                        <Input
                            className={cn(style['filter-input'], submited && typeProduct == '' && style['warning-input'])}
                            value={typeProduct}
                            onChange={({ target }) => set_typeProduct(target.value.replace(/[^0-9.]/g, ''))}
                        />
                    </Col>
                    <Col span={2}>{`Dày sp(mm)`}</Col>
                    <Col span={4}>
                        <Input
                            className={cn(style['filter-input'], submited && thickProduct == '' && style['warning-input'])}
                            value={thickProduct}
                            onChange={({ target }) => set_thickProduct(target.value.replace(/[^0-9.]/g, ''))}
                        />
                    </Col>
                    <Col span={2}>{`Rộng xẻ băng(mm)`}</Col>
                    <Col span={4}>
                        <Input
                            className={cn(style['filter-input'], submited && widthTape == '' && style['warning-input'])}
                            value={widthTape}
                            onChange={({ target }) => set_widthTape(target.value.replace(/[^0-9.]/g, ''))}
                        />
                    </Col>
                    <Col span={2}>
                        <Button onClick={onSumbitNewValue} className={style['filter-button']}>
                            Nhập dữ liệu
                        </Button>
                    </Col>
                </Row>
                <h3 style={{ color: 'black', marginTop: '50px' }}>Giá trị đã lưu</h3>
                <Row className={style['row-input']}>
                    <Col span={2}>{`Loại sp(mm)`}</Col>
                    <Col span={4}>
                        <Input className={style['filter-input']} value={filterType} onChange={({ target }) => set_filterType(target.value.replace(/[^0-9.]/g, ''))} />
                    </Col>
                    <Col span={2}>{`Dày sp(mm)`}</Col>
                    <Col span={4}>
                        <Input className={style['filter-input']} value={filterThick} onChange={({ target }) => set_filterThick(target.value.replace(/[^0-9.]/g, ''))} />
                    </Col>
                    <Col span={2}>{`Rộng xẻ băng(mm)`}</Col>
                    <Col span={4}>
                        <Input className={style['filter-input']} value={filterWidth} onChange={({ target }) => set_filterWidth(target.value.replace(/[^0-9.]/g, ''))} />
                    </Col>
                    <Col span={2}>
                        <Button onClick={onFiltterData} className={style['filter-button']} icon={<SearchOutlined style={{ fontSize: '16px', color: 'white' }} />}>
                            Tìm kiếm
                        </Button>
                    </Col>

                    <Col span={2} style={{ marginLeft: '15px' }}>
                        <Button onClick={onClearFilter} className={style['filter-button']}>
                            Xoá lọc
                        </Button>
                    </Col>
                </Row>
                <Table
                    columns={columns}
                    dataSource={dataSource.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
                    className={style['table']}
                    rowKey={'key'}
                    scroll={{ x: '100%' }}
                    pagination={false}
                    rowClassName={style['table-row']}
                />
                <Row justify={'end'} className={style['pagination']} style={{ marginTop: '10px' }}>
                    <Pagination
                        locale={{ items_per_page: '' }}
                        pageSizeOptions={[5, 10, 20]}
                        className={style['pagination-bar']}
                        pageSize={pageSize}
                        hideOnSinglePage
                        current={currentPage}
                        onChange={index => onChangePage(index)}
                        defaultCurrent={1}
                        total={dataSource.length}
                        showSizeChanger={false}
                    />
                    <Select
                        value={pageSize}
                        defaultValue={pageSize}
                        options={configs.PageSizes}
                        onChange={onChangePageSize}
                        style={{ marginLeft: '10px' }}
                        pagination={{ position: ['none', 'none'], pageSize: pageSize }}
                    />
                </Row>
            </div>
        </div>
    );
};

export default QuyChuanBangXe;
