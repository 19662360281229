import React, { useState, useMemo, useRef, useCallback, memo, useEffect } from 'react';
import { Button, Checkbox, notification, Row, Table } from 'antd';
import style from './danhSachYeuCauXeBang.module.less';
import { EditOutlined, DownOutlined, RightOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import { flattenRequest } from './util';
import SuaYeuCau from './SuaYeuCau/SuaYeuCau';
import { produce } from 'immer';
import { useDispatch } from 'react-redux';
import { appLoading } from '../../../../ducks/slices/appSlice';
import AxiosService from '../../../../common/AxiosService';
import { apis, keys } from '../../../../constants';
import { formatWeightText, localGet } from '../../../../helpers';
import dayjs from 'dayjs';
import ThemYeuCau from './SuaYeuCau/ThemYeuCau';
import { formatNumber } from '../../../../utils/common';
import { sumBy } from 'lodash';
import { useDeepEffect } from '../../../../components/hooks/useDeepEffect';

const TableYeuCau = ({ data, refetchData }) => {
    const columns = [
        {
            title: 'Chọn in',
            align: 'center',
            dataIndex: '',
            key: 'selectPrint',
            width: 60,
            render: (value, record) =>
                record.hideNgayLenh == false ? (
                    <Checkbox onChange={() => toogleSelectPrint(record.workOrderDate)} checked={printBlock.find(i => i.workOrderDate == record.workOrderDate)?.isPrint} />
                ) : null,
        },
        {
            title: 'Tiến trình',
            className: style['row-header'],
            children: [
                {
                    title: 'Ngày lệnh',
                    children: [
                        {
                            title: ' ',
                            dataIndex: 'workOrderDate',
                            key: 'workOrderDate',
                            width: 100,
                            render: (value, record) => (!record.hideNgayLenh ? value : ' '),
                        },
                    ],
                },
            ],
        },
        {
            title: 'STT No.',
            dataIndex: 'no',
            key: 'no',
            width: 70,
            render: (value, record) => (!record.hide ? value : ' '),
        },
        {
            title: 'Tôn cuộn',
            children: [
                {
                    title: 'Mã cuộn',
                    children: [
                        {
                            title: ' ',
                            dataIndex: 'nsmvCoilId',
                            key: 'nsmvCoilId',
                            width: 150,
                            render: (value, record) => <div className={style['important-text']}>{!record.hide ? value : '-'}</div>,
                        },
                    ],
                },
                {
                    title: 'Loại',
                    children: [
                        {
                            title: ' ',
                            dataIndex: 'type',
                            key: 'type',
                            width: 100,
                            render: (value, record) => (!record.hide ? value : '-'),
                        },
                    ],
                },
                {
                    title: 'Mác KH',
                    children: [
                        {
                            title: ' ',
                            dataIndex: 'nameCustomer',
                            key: 'nameCustomer',
                            width: 100,
                            render: (value, record) => (!record.hide ? value : '-'),
                        },
                    ],
                },
                {
                    title: 'Vị trí cuộn',
                    children: [
                        {
                            title: 'H',
                            align: 'center',
                            dataIndex: 'positionRoll1',
                            key: 'positionRoll1',
                            width: 80,
                            render: (value, record) => (!record.hide ? value : '-'),
                        },
                        {
                            title: 'K',
                            align: 'center',
                            dataIndex: 'positionRoll2',
                            key: 'positionRoll2',
                            width: 80,
                            render: (value, record) => (!record.hide ? value : '-'),
                        },
                    ],
                },
                {
                    title: 'M/D',
                    children: [
                        {
                            title: ' ',
                            dataIndex: 'md',
                            key: 'md',
                            width: 80,
                            render: (value, record) => (!record.hide ? value : '-'),
                        },
                    ],
                },
                {
                    title: 'Dày',
                    children: [
                        {
                            title: 'mm',
                            dataIndex: 'sizeThickness',
                            key: 'sizeThickness',
                            width: 80,
                            render: (value, record) => (!record.hide ? formatWeightText(value) : '-'),
                        },
                    ],
                },
                {
                    title: 'Rộng',
                    children: [
                        {
                            title: 'mm',
                            dataIndex: 'sizeWidth',
                            key: 'sizeWidth',
                            width: 80,
                            render: (value, record) => (!record.hide ? formatWeightText(value) : '-'),
                        },
                    ],
                },
                {
                    title: 'Rộng thực tế',
                    children: [
                        {
                            title: 'mm',
                            dataIndex: 'sizeWidthReal',
                            key: 'sizeWidthReal',
                            width: 100,
                            render: (value, record) => (!record.hide ? formatWeightText(value) : '-'),
                        },
                    ],
                },
                {
                    title: 'Khối lượng',
                    children: [
                        {
                            title: 'kg',
                            dataIndex: 'weight',
                            key: 'weight',
                            width: 100,
                            render: (value, record) => (!record.hide ? formatWeightText(value) : '-'),
                        },
                    ],
                },
            ],
        },
        {
            title: 'Quy cách',
            children: [
                {
                    title: 'Dày MIN',
                    children: [
                        {
                            title: 'mm',
                            dataIndex: 'thicknessMin',
                            key: 'thicknessMin',
                            width: 100,
                        },
                    ],
                },
                {
                    title: 'Dày sp',
                    children: [
                        {
                            title: 'mm',
                            dataIndex: 'thicknessProduct',
                            key: 'thicknessProduct',
                            width: 100,
                            render: (value) => formatWeightText(value)
                        },
                    ],
                },
            ],
        },
        {
            title: 'Băng xẻ',
            children: [
                {
                    title: 'Rộng',
                    children: [
                        {
                            title: 'mm',
                            dataIndex: 'sizeWidthTape',
                            key: 'sizeWidthTape',
                            width: 80,
                            render: (value) => formatWeightText(value)
                        },
                    ],
                },
                {
                    title: 'Loại sản phẩm',
                    children: [
                        {
                            title: ' ',
                            dataIndex: 'typeProduct',
                            key: 'typeProduct',
                            width: 100,
                        },
                    ],
                },
                {
                    title: 'Số dải',
                    children: [
                        {
                            title: ' ',
                            dataIndex: 'stripNumber',
                            key: 'stripNumber',
                            width: 80,
                        },
                    ],
                },
                {
                    title: 'Tổng độ rộng',
                    children: [
                        {
                            title: 'mm',
                            dataIndex: 'totalWidth',
                            key: 'totalWidth',
                            width: 100,
                            render: (value) => formatWeightText(value)
                        },
                    ],
                },
                {
                    title: 'Khối lượng tính toán',
                    children: [
                        {
                            title: 'kg',
                            dataIndex: 'weightCal',
                            key: 'weightCal',
                            width: 100,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Via',
            children: [
                {
                    title: 'Rộng (TT)',
                    children: [
                        {
                            title: 'mm',
                            dataIndex: 'sizeWidthVia',
                            key: 'sizeWidthVia',
                            width: 100,
                            render: (value, record) => (!record.hide ? formatWeightText(value) : '-'),
                        },
                    ],
                },

                {
                    title: 'Khối lượng',
                    children: [
                        {
                            title: 'kg',
                            dataIndex: 'mass',
                            key: 'mass',
                            width: 100,
                            render: (value, record) => (!record.hide ? formatWeightText(value) : '-'),
                        },
                    ],
                },
                {
                    title: 'Tổng via',
                    children: [
                        {
                            title: 'kg',
                            dataIndex: 'totalVia',
                            key: 'totalVia',
                            width: 100,
                            render: (value, record) => (!record.hide ? formatWeightText(value) : '-'),
                        },
                    ],
                },
                {
                    title: 'Tỷ lệ phế',
                    children: [
                        {
                            title: '%',
                            dataIndex: 'ratioTrash',
                            key: 'ratioTrash',
                            width: 100,
                            render: (value, record) => (!record.hide ? `${value}%` : '-'),
                        },
                    ],
                },
            ],
        },
        {
            title: 'Ghi chú',
            align: 'center',
            dataIndex: 'note',
            key: 'note',
            width: 250,
        },
        {
            title: ' ',
            width: 120,
            fixed: 'right',
            render: (value, item, index) => (
                <Row justify={'center'}>
                    <div className={style['table-button']} onClick={() => openAddNewModal()}>
                        {' '}
                        <PlusOutlined />
                    </div>
                    <div className={style['table-button']} onClick={() => openEditModal(item)}>
                        {' '}
                        <EditOutlined />
                    </div>
                    <div className={style['table-button']} onClick={() => deleteRow(item.id)}>
                        {' '}
                        <DeleteOutlined />
                    </div>
                </Row>
            ),
        },
    ];
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);
    const [isEdited, setEdited] = useState(false);
    const modalRef = useRef();
    const openEditModal = editData => {
        modalRef.current?.openEdit(editData);
    };
    const modalRef2 = useRef();
    const openAddNewModal = () => {
        modalRef2.current?.open();
    };

    const [editData, setEdit] = useState(data.lstBlockParent);
    useEffect(() => {
        setEdit(editData);
    }, [data.lstBlockParent]);

    const tableDataSource = flattenRequest(editData);

    //object lưu các block được chọn để in
    const [printBlock, set_printBlock] = useState([]);
    useDeepEffect(() => {
        // xoá đi những ngày không còn, thêm ngày mới
        var listWorkOrderDate = tableDataSource.filter(i => i.hideNgayLenh == false).map(i => ({ workOrderDate: i.workOrderDate, isPrint: true }));
        set_printBlock(prev => {
            var newState = prev.filter(i => !!listWorkOrderDate.find(ii => ii.workOrderDate == i.workOrderDate));
            for (const newDate of listWorkOrderDate) {
                if (!prev.find(i => i.workOrderDate == newDate.workOrderDate)) {
                    newState.push(newDate);
                }
            }
            return newState;
        });
    }, [tableDataSource]);
    //chọn in cho từng block (ngày lệnh)
    const toogleSelectPrint = date => {
        set_printBlock(
            produce(draft => {
                var selectIndex = draft.findIndex(i => i.workOrderDate == date);
                draft[selectIndex].isPrint = !draft[selectIndex].isPrint;
            }),
        );
    };
    //chọn in toàn bộ
    const tooglePrintAll = () => {
        set_printBlock(prev => prev.map(i => ({ ...i, isPrint: !i.isPrint })));
    };

    //xử lý in yêu cầu
    const [showPrint, set_showPrint] = useState(false);
    const onPressPrint = async e => {
        if (isOpen) e.stopPropagation();
        if (!showPrint) {
            set_showPrint(true);
        } else {
            const transformState = editData
                .map(block => ({
                    ...block,
                    id: null,
                    lstBlock: block.lstBlock.map(cuon => ({
                        ...cuon,
                        id: null,
                    })),
                }))
                .filter(b => printBlock.find(i => i.workOrderDate == b.workOrderDate)?.isPrint == true);
            if (transformState.length == 0) {
                notification.error({ message: 'Chưa chọn ngày lệnh để in' });
                return;
            }
            const dataSubmit = {
                id: null,
                productNo: data.productNo,
                workSplitDate: data.workSplitDate,
                lstBlockParent: transformState,
            };
            console.log(dataSubmit);
            //async call print
            set_showPrint(false);
        }
    };
    useEffect(() => {
        console.log('Print block', printBlock);
    }, [printBlock]);

    //lấy toàn bộ băng xẻ ứng với 1 cuộn, trừ ra id của 1 xẻ
    const getAllTapeInRoll = (rollId, id_xe) => {
        var tapeList = [];
        for (const block of editData) {
            for (const roll of block.lstBlock) {
                if (roll.rollId == rollId && tapeList.length == 0) {
                    tapeList = tapeList.concat(roll.lstStandard.filter(i => i.id != id_xe));
                }
            }
        }
        return tapeList;
    };

    //tìm thông tin của 1 cuộn trong yêu cầu
    const getRollInfoById = rollId => {
        var rollInfo = {};
        for (const { workOrderDate, lstBlock } of editData) {
            for (const { lstStandard, ...roll } of lstBlock) {
                if (roll.rollId == rollId && Object.keys(rollInfo).length == 0) {
                    rollInfo = { ...roll, workOrderDate };
                }
            }
        }
        return rollInfo;
    };
    const numberOfRow = useMemo(() => {
        var rollQty = 0;
        for (const block of editData) {
            for (const roll of block.lstBlock) {
                for (const xe of roll.lstStandard) {
                    rollQty += 1;
                }
            }
        }
        return rollQty;
    }, [editData]);

    const tableWidth = useMemo(() => {
        var width = 0;
        for (const col1 of columns) {
            if (!!col1.width) {
                width += col1.width;
            } else {
                for (const col2 of col1.children) {
                    var colW = col2.children[0]?.width || 0;
                    width += colW;
                }
            }
        }
        return width;
    }, []);

    // xoá hàng trong table
    //prettier-ignore
    const deleteRow = useCallback((rowId)=>{
        if(numberOfRow > 1) {
            console.log("Row id", rowId);
            setEdited(true);
            const [wOrderDate, rId, xId] = rowId.split('-');
            setEdit(produce(draft => {
                var iBlock = draft.findIndex(i => i.workOrderDate == wOrderDate);
                if (iBlock != -1) {
                    var iRoll = draft[iBlock].lstBlock.findIndex(i => i.rollId == rId);
                    if(iRoll != -1) {
                        var iXe = draft[iBlock].lstBlock[iRoll].lstStandard.findIndex(i => i.id == xId);
                        if(iXe != -1) {
                            var rItem = draft[iBlock].lstBlock[iRoll]
                            draft[iBlock].lstBlock[iRoll].lstStandard.splice(iXe, 1);
                            //recalculate via sau khi delete
                            var sizeWidthVia = formatNumber(Number(rItem.sizeWidth) - sumBy(draft[iBlock].lstBlock[iRoll].lstStandard, i => Number(i.sizeWidthTape) * Number(i.stripNumber)));
                            var totalVia = formatNumber((Number(rItem.weight) * 1 * (sizeWidthVia - Number(rItem.sizeWidth) + Number(rItem.sizeWidthReal))) / Number(rItem.sizeWidthReal)) ;
                            var mass = totalVia;
                            var ratioTrash = formatNumber(totalVia / Number(rItem.weight) * 100);
                            draft[iBlock].lstBlock[iRoll].sizeWidthVia = sizeWidthVia;
                            draft[iBlock].lstBlock[iRoll].totalVia = totalVia;
                            draft[iBlock].lstBlock[iRoll].mass = mass;
                            draft[iBlock].lstBlock[iRoll].ratioTrash = ratioTrash;
                            
                            //xoá đi cuộn không có xẻ, block không có cuộn
                            if(draft[iBlock].lstBlock[iRoll].lstStandard.length == 0) {
                                draft[iBlock].lstBlock.splice(iRoll, 1);
                                if(draft[iBlock].lstBlock.length == 0) {
                                    draft.splice(iBlock,1)
                                }
                            }
                        }
                    }
                }
            }))
        }
    },[numberOfRow])

    //thêm mới 1 hàng trong table
    //prettier-ignore
    const addRow = useCallback(newEdit => {
        console.log("New added", newEdit);
        setEdited(true);
        const { workOrderDate,hide,hideNgayLenh, id, id_xe, thicknessMin, thicknessProduct, sizeWidthTape, typeProduct, stripNumber, totalWidth, weightCal, note, ...rollData } = newEdit;
        setEdit(produce(draft => {
            //nếu cuộn mới đã có trong yêu cầu, lấy ngày lệnh của chính cuộn đó trong yêu cầu
            //còn không thì lấy ngày lệnh theo form nhập 
            var newWorkOrderDate = workOrderDate;
            for (const b of draft) {
                for(const roll of b.lstBlock ) {
                    if(roll.rollId == rollData.rollId ) {
                        newWorkOrderDate = b.workOrderDate
                    }
                }
            }

            var iBlock = draft.findIndex(i => i.workOrderDate == newWorkOrderDate);
            var new_xe = {id:id_xe,thicknessMin, thicknessProduct, sizeWidthTape, typeProduct, stripNumber, totalWidth, weightCal, note};
            if (iBlock != -1) {
                var iRoll = draft[iBlock].lstBlock.findIndex(i => i.rollId == rollData.rollId);
                if(iRoll != -1) {
                    var newIDXe = draft[iBlock].lstBlock[iRoll].lstStandard[draft[iBlock].lstBlock[iRoll].lstStandard.length - 1].id + 1;
                    var newStandard = draft[iBlock].lstBlock[iRoll].lstStandard.map(xe => ({
                        ...xe,
                        weightCal: formatNumber((Number(rollData.weight) * Number(xe.sizeWidthTape) * Number(xe.stripNumber)) / Number(rollData.sizeWidthReal))
                    })).concat({...new_xe ,id: newIDXe});

                    draft[iBlock].lstBlock[iRoll] = {...rollData, lstStandard: newStandard}
                }
                else draft[iBlock].lstBlock.push({...rollData,lstStandard: [new_xe]})
            }
            else {
                var newRoll = {...rollData, lstStandard:[new_xe]}
                draft.push({
                    id:draft[draft.length - 1].id + 1, workOrderDate: newWorkOrderDate, lstBlock: [newRoll]
                })
            }
            }),
        );
    }, []);

    // sửa 1 hàng trong table
    //prettier-ignore
    const editRow = useCallback((newEdit, oldEdit) => {
        // console.log('Old id', oldEdit);
        // console.log('Newly edit', newEdit);
        setEdited(true);
        const {workOrderDate, id, id_xe,
            thicknessMin,
            thicknessProduct,
            sizeWidthTape,
            typeProduct,
            stripNumber,
            totalWidth,
            weightCal,
            note,
            ...rollData} = newEdit;
        
        setEdit(produce(draft => {
            if(!!oldEdit) {
                var iBlockOld = draft.findIndex(i => i.workOrderDate == oldEdit.workOrderDate);
                if(iBlockOld != -1) {
                    var iRollOld = draft[iBlockOld].lstBlock.findIndex(i => i.rollId == oldEdit.rollId);
                    if(iRollOld != -1) {
                        //thay đổi 1 số giá trị của tôn cuôn cũ, đồng thời xoá đi băng xẻ với id tương ứng
                        //nếu như cuộn không còn băng xẻ, xoá cuộn ra khỏi yêu cầu
                        if(draft[iBlockOld].lstBlock[iRollOld].lstStandard.length > 1) {
                            draft[iBlockOld].lstBlock[iRollOld] = {
                                ...draft[iBlockOld].lstBlock[iRollOld],
                                lstStandard: draft[iBlockOld].lstBlock[iRollOld].lstStandard.filter(i => i.id != oldEdit.id_xe),
                                sizeWidthVia: oldEdit.sizeWidthVia,
                                totalVia: oldEdit.totalVia,
                                ratioTrash: oldEdit.ratioTrash,
                                mass: oldEdit.mass,
                                workSplitDate: undefined,
                            }
                        }
                        else {
                            draft[iBlockOld].lstBlock.splice(iRollOld, 1);
                            if(draft[iBlockOld].lstBlock.length == 0) {
                                draft.splice(iBlockOld,1)
                            }
                        }
                    }
                    else {
                        // không thể xảy ra vì luôn tìm được cuộn này trong yêu cầu 
                        // nếu xảy ra (bug) thì code tiếp ở đây
                    }
                }
                else {
                    // chưa có TH thay đổi ngày lệnh (workOrderDate), làm sau
                }
            }
            var iBlock = draft.findIndex(i => i.workOrderDate == newEdit.workOrderDate);
            if(iBlock != -1) {
                var iRoll = draft[iBlock].lstBlock.findIndex(i => i.rollId == newEdit.rollId);
                var xeData = {
                    id: id_xe,
                    thicknessMin,
                    thicknessProduct,
                    sizeWidthTape,
                    typeProduct,
                    stripNumber,
                    totalWidth,
                    weightCal,
                    note
                }
                if(iRoll != -1) {
                    //TH cuộn mới edit đã có trong yêu càu => sửa thông tin cuộn đồng thời sửa băng xẻ ứng với id_xe tương ứng
                    var newStandard = [];
                    if(!oldEdit) {
                       newStandard = draft[iBlock].lstBlock[iRoll].lstStandard.map(i => i.id != id_xe ? ({
                        ...i,
                        weightCal: formatNumber((Number(rollData.weight) * Number(i.sizeWidthTape) * Number(i.stripNumber)) / Number(rollData.sizeWidthReal))
                       }) : xeData);
                    }
                    else {
                        var newIDXe = draft[iBlock].lstBlock[iRoll].lstStandard[draft[iBlock].lstBlock[iRoll].lstStandard.length - 1].id + 1
                        newStandard = draft[iBlock].lstBlock[iRoll].lstStandard.map((i,_,arr)=> ({
                            ...i,
                            weightCal: formatNumber((Number(rollData.weight) * Number(i.sizeWidthTape) * Number(i.stripNumber)) / Number(rollData.sizeWidthReal))
                        })).concat({...xeData, id: newIDXe})
                    }

                    draft[iBlock].lstBlock[iRoll] = {...rollData,lstStandard: newStandard}
                }
                else {
                    //TH cuộn mới edit chưa có trong yêu cầu => thêm cuộn mới
                    draft[iBlock].lstBlock.push({...rollData, lstStandard:[xeData],})
                }
            }
            else {
                // chưa có TH thay đổi ngày lệnh (workOrderDate), làm sau
            }      
        }));
    }, []);

    // useEffect(() => {
    //     console.log('Data đã được edit', editData);
    // }, [editData]);

    //tải xuống file yêu cầu
    const downloadFileYeuCau = e => {
        e.stopPropagation();
        const transformState = editData.map(block => ({
            ...block,
            id: null,
            lstBlock: block.lstBlock.map(cuon => ({
                ...cuon,
                id: null,
            })),
        }));
        const dataSubmit = {
            id: null,
            productNo: data.productNo,
            workSplitDate: data.workSplitDate,
            lstBlockParent: transformState,
        };
        dispatch(appLoading(true));

        fetch(apis.Url_Api_Post_DownloadXeBang, {
            method: 'POST',
            body: JSON.stringify(dataSubmit),
            headers: {
                'Content-Type': 'application/json',
                Authorization: localGet(keys.access_token),
            },
        })
            .then(response => response.blob())
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res]));
                const link = document.createElement('a');
                link.href = url;
                link.download = 'YeuCauBangXe_' + data.workSplitDate.split('/').join('') + '.xlsx';
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                dispatch(appLoading(false));
            })
            .catch(err => {
                console.log('Error download', err);
                dispatch(appLoading(false));
                notification.error({ message: 'Tải yêu cầu thất bại' });
            });
    };

    //submit yêu cầu vừa sửa
    const submitEditYeuCau = async e => {
        e.stopPropagation();
        const transformState = editData.map(block => ({
            ...block,
            id: null,
            lstBlock: block.lstBlock.map(cuon => ({
                ...cuon,
                id: null,
            })),
        }));
        const dataSubmit = {
            id: data.id,
            productNo: data.productNo,
            workSplitDate: data.workSplitDate,
            lstBlockParent: transformState,
        };
        //console.log('Data about to submit', dataSubmit);
        dispatch(appLoading(true));
        try {
            const { data } = await AxiosService.post(apis.Url_Api_Post_EditXeBang, dataSubmit);
            if (data.status == 200) {
                await refetchData();
                setEdited(false);
                setOpen(false);
                notification.success({ message: 'Sửa đổi yêu cầu thành công' });
            }
        } catch (e) {
            notification.error({ message: 'Sửa đổi yêu cầu thất bại' });
        } finally {
            dispatch(appLoading(false));
        }
    };

    const revertChange = e => {
        e.stopPropagation();
        setEdit(data.lstBlockParent);
        setEdited(false);
    };

    return (
        <div style={{ marginTop: '50px' }}>
            <Row
                justify={'space-between'}
                style={{ alignItems: 'center' }}
                className={style['row-item']}
                onClick={() => {
                    setOpen(!isOpen);
                    console.log('Request flatten', flattenRequest(editData));
                }}
            >
                <Row style={{ alignItems: 'center' }}>
                    <h4>Mã lệnh {data.productNo}</h4>
                    <h4 style={{ color: 'black', marginLeft: '50px' }}>Ngày tạo: {data.workSplitDate}</h4>
                </Row>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: 'auto', flexDirection: 'row' }}>
                    {isEdited && (
                        <Button className={style['submit-button']} color="red" onClick={submitEditYeuCau}>
                            Lưu thay đổi
                        </Button>
                    )}
                    {isEdited && (
                        <Button className={style['cancel-button']} color="red" onClick={revertChange}>
                            Xoá thay đổi
                        </Button>
                    )}
                    <Button className={showPrint ? style['filter-button'] : style['ghost-button']} onClick={onPressPrint}>
                        {showPrint ? 'Xác nhận in' : ' In yêu cầu'}
                    </Button>
                    <Button className={style['filter-button']} onClick={downloadFileYeuCau}>
                        Tải file
                    </Button>
                    {isOpen ? <DownOutlined style={{ color: '#2c3782' }} /> : <RightOutlined style={{ color: '#2c3782' }} />}
                </div>
            </Row>

            {isOpen && (
                <Table
                    pagination={false}
                    columns={columns.filter(c => (showPrint ? true : c.key !== 'selectPrint'))}
                    dataSource={tableDataSource}
                    className={style['table']}
                    rowKey={record => record.id}
                    scroll={{ x: tableWidth }}
                />
            )}
            <SuaYeuCau editRow={editRow} ref={modalRef} getAllTapeInRoll={getAllTapeInRoll} getRollInfoById={getRollInfoById} />
            <ThemYeuCau addRow={addRow} ref={modalRef2} getAllTapeInRoll={getAllTapeInRoll} getRollInfoById={getRollInfoById} />
        </div>
    );
};
export default memo(TableYeuCau);
